import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./MeetingComponent.module.css";

import * as actions from "../../../../store/actions/index";
import closeButton from "../../../../assets/images/meetingclose.png";

class MeetingComponent extends Component {

    state = {
        meetingForm: {
            subject: "",
            date: {
                startTime: "",
                startDate: "",
                endTime: "",
                endDate: "",
                timezone: "",
            },
            virtualRoom: "",
            hostMeetingURL: "",
            visitorMeetingURL: "",
            hostAccessCode: "",
            visitorAccessCode: "",
            participants: "",
            meetingIndex: 0
        }
    }

    componentDidMount() {
        console.log("meeting component mounted");

        let updatedMeetingForm = {
            ...this.state.meetingForm,
            subject: this.props.subject,
            date: {
                startTime: this.props.date.startTime,
                startDate: this.props.date.startDate,
                endTime: this.props.date.endTime,
                endDate: this.props.date.endDate,
                timezone: this.props.date.timezone,
            },
            virtualRoom: this.props.virtualRoom,
            hostMeetingURL: this.props.hostMeetingURL,
            visitorMeetingURL: this.props.visitorMeetingURL,
            hostAccessCode: this.props.hostAccessCode,
            visitorAccessCode: this.props.visitorAccessCode,
            participants: this.props.participants,
            meetingIndex: this.props.meetingIndex
        }
        this.setState({ meetingForm: updatedMeetingForm });
    }

    updateMeeting = () => {

        let meetingData = {
            subject: this.state.meetingForm.subject,
            date: this.state.meetingForm.date,
            virtualRoom: this.state.meetingForm.virtualRoom,
            hostMeetingURL: this.state.meetingForm.hostMeetingURL,
            visitorMeetingURL: this.state.meetingForm.visitorMeetingURL,
            hostAccessCode: this.state.meetingForm.hostAccessCode,
            visitorAccessCode: this.state.meetingForm.visitorAccessCode,
            participants: this.state.meetingForm.participants,
            meetingIndex: this.state.meetingForm.meetingIndex
        }
        console.log("data before sending: " + meetingData);

        this.props.onUpdateMeetingBox(true, meetingData);
    }

    joinMeeting = () => {
        window.open(this.props.hostMeetingURL);
    }

    getOrdinal = (d) => {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    render() {

        // console.log("Subject with index : " + this.props.subject + ", " + this.props.meetingIndex);

        let meetingName = null;

        if (this.props.meetingIndex === 0) {
            meetingName = "Next Meeting";
        } else {
            meetingName = this.props.meetingIndex + 1 + this.getOrdinal(this.props.meetingIndex + 1) + " Meeting";
        }

        return (
            <div className={cssClasses.MeetingComponent}>
                <div style={{ height: "2.68%", width: "100%", margin: "0 0 4.27% 0", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    {/* <p style={{ height: "100%", width: "96.65%", margin: "0 0 0 0", font: "normal normal bold 16px/18px Arial" }}>Meeting</p> */}
                    <p style={{ height: "100%", width: "96.65%", margin: "0 0 0 0", font: "normal normal bold 16px/18px Arial" }}>{meetingName}</p>
                    <img className={cssClasses.CloseButton} src={closeButton} onClick={this.props.delete}></img>
                </div>
                <p className={cssClasses.TextHeading}>Subject</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.subject}</p>
                </div>
                <p className={cssClasses.TextHeading}>Date</p>
                <div style={{ height: "5.04%", width: "100%", margin: "0 0 1.22% 0", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    <p className={cssClasses.DateData}>{this.props.date.startDate}</p>
                    <p className={cssClasses.TimeData}>{this.props.date.startTime}</p>
                    <p style={{ margin: "0 2.44% 1% 2.44%" }}>to</p>
                    <p className={cssClasses.DateData}>{this.props.date.endDate}</p>
                    <p className={cssClasses.TimeData}>{this.props.date.endTime}</p>
                </div>
                <div className={cssClasses.TimeZoneData}>
                    <p style={{ font: "normal normal 13px/15px Arial" }}>Timezone:</p>
                    <p style={{ font: "normal normal 13px/15px Arial" }}>{this.props.date.timezone}</p>
                </div>
                <p className={cssClasses.TextHeading}>Virtual Room</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.virtualRoom}</p>
                </div>
                <p className={cssClasses.TextHeading}>Meeting URL Host (PC)</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.hostMeetingURL}</p>
                </div>
                <p className={cssClasses.TextHeading}>Meeting URL Visitor (PC)</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.visitorMeetingURL}</p>
                </div>
                <p className={cssClasses.TextHeading}>Access Code Host</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.hostAccessCode}</p>
                </div>
                <p className={cssClasses.TextHeading}>Access Code Visitor</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.visitorAccessCode}</p>
                </div>
                <p className={cssClasses.TextHeading}>Participants</p>
                <div className={cssClasses.ParticipantsData}>
                    <p className={cssClasses.Text}>{this.props.participants}</p>
                </div>
                <div className={cssClasses.MeetingActions}>
                    <button style={{ height: "100%", width: "19.51%", margin: "0 7.32% 0 0", border: "0", background: "#843D96 0% 0% no-repeat padding-box", font: "normal normal bold 14px/16px Arial", color: "#FFFFFF" }} onClick={this.joinMeeting}>Join</button>
                    <button style={{ height: "100%", width: "19.51%", border: "1px solid #843D96", borderRadius: " 2px", font: "normal normal bold 14px/16px Arial", color: "#843D96" }} onClick={this.updateMeeting}>Modify</button>
                </div>
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateMeetingBox: (value, body) =>
            dispatch(actions.updateMeetingBox(value, body))
    };
};

export default connect(null, mapDispatchToProps)(MeetingComponent);