import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadTotalPresentationViews = () => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM,
        error: false
    };
};

export const loadTotalPresentationViewsSuccess = (totalPresentationViewsShowroom) => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM_SUCCESS,
        error: false,
        totalPresentationViewsShowroom: totalPresentationViewsShowroom
    };
};

export const loadTotalPresentationViewsFail = (message) => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM_FAIL,
        error: true,
        message: message
    };
};

export const getTotalPresentationViewsShowroom = (adminUserId, showroomName) => {
    return (dispatch) => {
        dispatch(loadTotalPresentationViews());

        let totalPresentationViewsUsers = [];
        let totalPresentationViewsDates = [];
        let totalPresentationViewsActive = [];
        let totalPresentationViewsShowroom = null;

        let data = {
            adminUserId: adminUserId,
            showroomName: showroomName
        }

        // Total presentation views
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/gettotalpresentationviewsshowroom',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalPresentationViewsAnalyticsData) {
                    totalPresentationViewsUsers.push(dataObj);
                }

                for (const dailyUser of totalPresentationViewsUsers) {
                    totalPresentationViewsDates.push(dailyUser.date);
                    totalPresentationViewsActive.push(dailyUser.presentationViews);
                }

                let totalPresentationViewsObject = {
                    labels: totalPresentationViewsDates,
                    data: totalPresentationViewsActive
                }

                totalPresentationViewsShowroom = totalPresentationViewsObject;
                dispatch(loadTotalPresentationViewsSuccess(totalPresentationViewsShowroom));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadTotalPresentationViewsFail(error.response.data.error));
                }
            })
    }
};