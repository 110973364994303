export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAIL = "AUTH_FORGOT_PASSWORD_FAIL";

export const AUTH_VERIFY_PASSWORD_RESET = "AUTH_VERIFY_PASSWORD_RESET";
export const AUTH_VERIFY_PASSWORD_RESET_SUCCESS = "AUTH_VERIFY_PASSWORD_RESET_SUCCESS";
export const AUTH_VERIFY_PASSWORD_RESET_FAIL = "AUTH_VERIFY_PASSWORD_RESET_FAIL";

export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAIL = "AUTH_CHANGE_PASSWORD_FAIL";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const AUTH_SAVE_OOBCODE = "AUTH_SAVE_OOBCODE";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERROR_RESET = "AUTH_ERROR_RESET";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const PROFILE_CHANGE_EMAIL = "PROFILE_CHANGE_EMAIL";
export const PROFILE_CHANGE_EMAIL_SUCCESS = "PROFILE_CHANGE_EMAIL_SUCCESS";
export const PROFILE_CHANGE_EMAIL_FAIL = "PROFILE_CHANGE_EMAIL_FAIL";

export const PROFILE_CHANGE_PASSWORD = "PROFILE_CHANGE_PASSWORD";
export const PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST = "PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST";
export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS";
export const PROFILE_CHANGE_PASSWORD_FAIL = "PROFILE_CHANGE_PASSWORD_FAIL";

export const PROFILE_UPDATE_USER_DETAILS = "PROFILE_UPDATE_USER_DETAILS";
export const PROFILE_UPDATE_USER_DETAILS_SUCCESS = "PROFILE_UPDATE_USER_DETAILS_SUCCESS";
export const PROFILE_UPDATE_USER_DETAILS_FAIL = "PROFILE_UPDATE_USER_DETAILS_FAIL";

export const BOOTH_CREATOR = "BOOTH_CREATOR";
export const ROOM_CREATOR = "ROOM_CREATOR";
export const SHOWROOMS = "SHOWROOMS";
export const MEETINGS = "MEETINGS";
export const ANALYTICS = "ANALYTICS";

//Showrooms

export const ENABLE_SET_UP_SHOWROOM_BOX = "ENABLE_SET_UP_SHOWROOM_BOX";
export const DISABLE_SET_UP_SHOWROOM_BOX = "DISABLE_SET_UP_SHOWROOM_BOX";

export const SET_UP_SHOWROOM = "SET_UP_SHOWROOM";
export const SET_UP_SHOWROOM_SUCCESS = "SET_UP_SHOWROOM_SUCCESS";
export const SET_UP_SHOWROOM_FAIL = "SET_UP_SHOWROOM_FAIL";
export const SET_UP_SHOWROOM_STATE_RESET = "SET_UP_SHOWROOM_STATE_RESET";
export const SET_UP_SHOWROOM_UPDATE_INDEX = "SET_UP_SHOWROOM_UPDATE_INDEX";

export const ENABLE_UPDATE_SHOWROOM_BOX = "ENABLE_UPDATE_SHOWROOM_BOX";
export const DISABLE_UPDATE_SHOWROOM_BOX = "DISABLE_UPDATE_SHOWROOM_BOX";

export const UPDATE_SHOWROOM = "UPDATE_SHOWROOM";
export const UPDATE_SHOWROOM_SUCCESS = "UPDATE_SHOWROOM_SUCCESS";
export const UPDATE_SHOWROOM_FAIL = "UPDATE_SHOWROOM_FAIL";
export const UPDATE_SHOWROOM_STATE_RESET = "UPDATE_SHOWROOM_STATE_RESET";

//Meetings

export const ENABLE_SCHEDULE_MEETING_BOX = "ENABLE_SCHEDULE_MEETING_BOX";
export const DISABLE_SCHEDULE_MEETING_BOX = "DISABLE_SCHEDULE_MEETING_BOX";

export const SCHEDULE_MEETING = "SCHEDULE_MEETING";
export const SCHEDULE_MEETING_SUCCESS = "SCHEDULE_MEETING_SUCCESS";
export const SCHEDULE_MEETING_FAIL = "SCHEDULE_MEETING_FAIL";
export const SCHEDULE_MEETING_STATE_RESET = "SCHEDULE_MEETING_STATE_RESET";
export const SCHEDULE_MEETING_UPDATE_INDEX = "SCHEDULE_MEETING_UPDATE_INDEX";

export const ENABLE_UPDATE_MEETING_BOX = "ENABLE_UPDATE_MEETING_BOX";
export const DISABLE_UPDATE_MEETING_BOX = "DISABLE_UPDATE_MEETING_BOX";

export const UPDATE_MEETING = "UPDATE_MEETING";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_MEETING_FAIL = "UPDATE_MEETING_FAIL";
export const UPDATE_MEETING_STATE_RESET = "UPDATE_MEETING_STATE_RESET";

export const EDIT_BOOTH = "EDIT_BOOTH";
export const EDIT_ROOM = "EDIT_ROOM";
export const RESET_EDITING = "RESET_EDITING";

export const SHOWROOM_DASHBOARD = "SHOWROOM_DASHBOARD";
export const MEETING_DASHBOARD = "MEETING_DASHBOARD";
export const RESET_ANALYTICS = "RESET_ANALYTICS";

//Showroom actions 
export const DAILY_USERS_SHOWROOM = "DAILY_USERS_SHOWROOM";
export const DAILY_USERS_SHOWROOM_SUCCESS = "DAILY_USERS_SHOWROOM_SUCCESS";
export const DAILY_USERS_SHOWROOM_FAIL = "DAILY_USERS_SHOWROOM_FAIL";

export const TOTAL_PRESENTATION_VIEWS_SHOWROOM = "TOTAL_PRESENTATION_VIEWS_SHOWROOM";
export const TOTAL_PRESENTATION_VIEWS_SHOWROOM_SUCCESS = "TOTAL_PRESENTATION_VIEWS_SHOWROOM_SUCCESS";
export const TOTAL_PRESENTATION_VIEWS_SHOWROOM_FAIL = "TOTAL_PRESENTATION_VIEWS_SHOWROOM_FAIL";

export const TOTAL_VIDEO_SHOWROOM = "TOTAL_VIDEO_SHOWROOM";
export const TOTAL_VIDEO_SHOWROOM_SUCCESS = "TOTAL_VIDEO_SHOWROOM_SUCCESS";
export const TOTAL_VIDEO_SHOWROOM_FAIL = "TOTAL_VIDEO_SHOWROOM_FAIL";

export const TOTAL_TIME_SPENT_SHOWROOM = "TOTAL_TIME_SPENT_SHOWROOM";
export const TOTAL_TIME_SPENT_SHOWROOM_SUCCESS = "TOTAL_TIME_SPENT_SHOWROOM_SUCCESS";
export const TOTAL_TIME_SPENT_SHOWROOM_FAIL = "TOTAL_TIME_SPENT_SHOWROOM_FAIL";

//Meeting actions 
export const DAILY_USERS_MEETING = "DAILY_USERS_MEETING";
export const DAILY_USERS_MEETING_SUCCESS = "DAILY_USERS_MEETING_SUCCESS";
export const DAILY_USERS_MEETING_FAIL = "DAILY_USERS_MEETING_FAIL";

export const TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE = "TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE";
export const TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_SUCCESS = "TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_SUCCESS";
export const TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_FAIL = "TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_FAIL";

export const TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE = "TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE";
export const TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_SUCCESS = "TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_SUCCESS";
export const TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_FAIL = "TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_FAIL";

export const TOTAL_VIDEO_MEETING_OUTSIDE= "TOTAL_VIDEO_MEETING_OUTSIDE";
export const TOTAL_VIDEO_MEETING_OUTSIDE_SUCCESS = "TOTAL_VIDEO_MEETING_OUTSIDE_SUCCESS";
export const TOTAL_VIDEO_MEETING_OUTSIDE_FAIL = "TOTAL_VIDEO_MEETING_OUTSIDE_FAIL";

export const TOTAL_VIDEO_MEETING_INSIDE= "TOTAL_VIDEO_MEETING_INSIDE";
export const TOTAL_VIDEO_MEETING_INSIDE_SUCCESS = "TOTAL_VIDEO_MEETING_INSIDE_SUCCESS";
export const TOTAL_VIDEO_MEETING_INSIDE_FAIL = "TOTAL_VIDEO_MEETING_INSIDE_FAIL";

export const DAILY_MEETINGS = "DAILY_MEETINGS";
export const DAILY_MEETINGS_SUCCESS = "DAILY_MEETINGS_SUCCESS";
export const DAILY_MEETINGS_FAIL = "DAILY_MEETINGS_FAIL";

export const DAILY_MEETINGS_DURATION = "DAILY_MEETINGS_DURATION";
export const DAILY_MEETINGS_DURATION_SUCCESS = "DAILY_MEETINGS_DURATION_SUCCESS";
export const DAILY_MEETINGS_DURATION_FAIL = "DAILY_MEETINGS_DURATION_FAIL";

export const TOTAL_TIME_SPENT_MEETING = "TOTAL_TIME_SPENT_MEETING";
export const TOTAL_TIME_SPENT_MEETING_SUCCESS = "TOTAL_TIME_SPENT_MEETING_SUCCESS";
export const TOTAL_TIME_SPENT_MEETING_FAIL = "TOTAL_TIME_SPENT_MEETING_FAIL";