import React from "react";
import cssClasses from "./GraphComponent.module.css";

import { Line } from 'react-chartjs-2';

const graphComponent = (props) => {

    props.graph.labels = props.labelsValue;
    props.graph.datasets[0].data = props.dataValue;
    console.log(props.graph.datasets[0].label);

    if (props.graph.labels[0] !== 0) {
        props.graph.labels.unshift(0);
    }

    if (props.graph.datasets[0].data.length < 2) {
        props.graph.datasets[0].data.unshift(0);
    } else {
        if (props.graph.datasets[0].data[0] !== 0) {
            props.graph.datasets[0].data.unshift(0);
        }
    }

    let graph = <Line
        height={340} width={504} data={props.graph} options={props.dataOptions, {
            maintainAspectRatio: false, responsive: true, layout: {
                padding: {
                    top: 15,
                    right: 15,
                    bottom: 15,
                    left: 15
                }
            }
        }} />

    return (
        <div className={cssClasses.Graph}>
            {graph}
        </div>
    )
}

export default graphComponent;



// let totalTimeSpentGraph = <div className={cssClasses.BarGraph}>
        //     <LoadingSpinner />
        // </div>

        // if (!this.props.loadingTotalTimeSpentShowroom && this.props.message === "") {
        //     timeSpentData.labels = this.props.totalUsersTimeSpentShowroom.labels;
        //     timeSpentData.datasets[0].data = this.props.totalUsersTimeSpentShowroom.data;

        //     totalTimeSpentGraph = <div className={cssClasses.BarGraph}>
        //         <Line height={340} width={504} data={timeSpentData} options={dataOptions, {
        //             maintainAspectRatio: false, responsive: true, layout: {
        //                 padding: {
        //                     top: 15,
        //                     right: 15,
        //                     bottom: 15,
        //                     left: 15
        //                 }
        //             }
        //         }} />
        //     </div>
        // } else if (!this.props.loadingTotalTimeSpentShowroom && this.props.message !== "") {
        //     totalTimeSpentGraph = <div className={cssClasses.BarGraph}>
        //         No analytics data found
        //     </div>
        // }