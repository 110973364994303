import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadDailyMeetingsDuration = () => {
    return {
        type: actionTypes.DAILY_MEETINGS_DURATION,
        error: false
    };
};

export const loadDailyMeetingsDurationSuccess = (dailyMeetingsDuration) => {
    return {
        type: actionTypes.DAILY_MEETINGS_DURATION_SUCCESS,
        error: false,
        dailyMeetingsDuration: dailyMeetingsDuration
    };
};

export const loadDailyMeetingsDurationFail = (message) => {
    return {
        type: actionTypes.DAILY_MEETINGS_DURATION_FAIL,
        error: true,
        message: message
    };
};

export const getDailyMeetingsDuration = (adminUserId, meetingSubject) => {
    return (dispatch) => {
        dispatch(loadDailyMeetingsDuration());

        let dailyUsers = [];
        let dailyUsersDates = [];
        let dailyUsersActive = [];
        let dailyMeetingsDuration = null;

        let data = {
            adminUserId: adminUserId,
            meetingSubject: meetingSubject
        }

        // Daily Users
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/getdailymeetingsduration',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalDailyMeetingsDuration) {
                    dailyUsers.push(dataObj);
                }
                for (const dailyUser of dailyUsers) {
                    dailyUsersDates.push(dailyUser.date);
                    dailyUsersActive.push(dailyUser.meetingDuration);
                }
                
                let dailyUsersObject = {
                    labels: dailyUsersDates,
                    data: dailyUsersActive
                }

                dailyMeetingsDuration = dailyUsersObject;
                dispatch(loadDailyMeetingsDurationSuccess(dailyMeetingsDuration));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadDailyMeetingsDurationFail(error.response.data.error));
                }
            })
    }
};