import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadDailyUsers = () => {
    return {
        type: actionTypes.DAILY_USERS_SHOWROOM,
        error: false
    };
};

export const loadDailyUsersSuccess = (dailyUsersShowroom) => {
    return {
        type: actionTypes.DAILY_USERS_SHOWROOM_SUCCESS,
        error: false,
        dailyUsersShowroom: dailyUsersShowroom
    };
};

export const loadDailyUsersFail = (message) => {
    return {
        type: actionTypes.DAILY_USERS_SHOWROOM_FAIL,
        error: true,
        message: message
    };
};

export const getDailyUsersShowroom = (adminUserId, showroomName) => {
    return (dispatch) => {
        dispatch(loadDailyUsers());

        let dailyUsers = [];
        let dailyUsersDates = [];
        let dailyUsersActive = [];
        let dailyUsersShowroom = null;

        let data = {
            adminUserId: adminUserId,
            showroomName: showroomName
        }

        // Daily Users
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/getdailyusersshowroom',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.dailyUsersAnalyticsData) {
                    dailyUsers.push(dataObj);
                }
                for (const dailyUser of dailyUsers) {
                    dailyUsersDates.push(dailyUser.date);
                    dailyUsersActive.push(dailyUser.users);
                }
                let dailyUsersObject = {
                    labels: dailyUsersDates,
                    data: dailyUsersActive
                }

                dailyUsersShowroom = dailyUsersObject;
                dispatch(loadDailyUsersSuccess(dailyUsersShowroom));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadDailyUsersFail(error.response.data.error));
                }
            })
    }
};