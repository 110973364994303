import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadTotalUserTimeSpent = () => {
    return {
        type: actionTypes.TOTAL_TIME_SPENT_SHOWROOM,
        error: false
    };
};

export const loadTotalUserTimeSpentSuccess = (totalTimeSpentShowroom) => {
    return {
        type: actionTypes.TOTAL_TIME_SPENT_SHOWROOM_SUCCESS,
        error: false,
        totalTimeSpentShowroom: totalTimeSpentShowroom
    };
};

export const loadTotalUserTimeSpentFail = (message) => {
    return {
        type: actionTypes.TOTAL_TIME_SPENT_SHOWROOM_FAIL,
        error: true,
        message: message
    };
};

export const getTotalUserTimeSpentShowroom = (adminUserId, showroomName) => {
    return (dispatch) => {
        dispatch(loadTotalUserTimeSpent());

        let totalTimeSpentUsers = [];
        let totalTimeSpentDates = [];
        let totalTimeSpentActive = [];
        let totalTimeSpentShowroom = null;

        let data = {
            adminUserId: adminUserId,
            showroomName: showroomName
        }

        // Total video watch time
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/gettotaltimespentshowroom',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalUserTimeSpentData) {
                    totalTimeSpentUsers.push(dataObj);
                }

                for (const dailyUser of totalTimeSpentUsers) {
                    totalTimeSpentDates.push(dailyUser.date);
                    totalTimeSpentActive.push(dailyUser.userTimeSpent);
                }

                let object = {
                    labels: totalTimeSpentDates,
                    data: totalTimeSpentActive
                }

                totalTimeSpentShowroom = object;
                dispatch(loadTotalUserTimeSpentSuccess(totalTimeSpentShowroom));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadTotalUserTimeSpentFail(error.response.data.error));
                }
            })
    }
};