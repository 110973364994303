import React from "react";
import { connect } from "react-redux";

import cssClasses from "./Header.module.css";

import HeaderName from "../HeaderName/HeaderName";
import Profile from "../Profile/Profile";

const header = (props) => {

  let headerButton = <div className={cssClasses.scheduleMeetingArea}></div>;

  console.log("props.appState : " + props.appState);

  if (props.appState === "showrooms") {
    headerButton = <div className={cssClasses.scheduleMeetingButton} onClick={props.showroomClicked}>
      <p style={{ height: "57.14%", width: "86.84%", font: "normal normal bold 14px/16px Arial", margin: "3.95% 6.56% 3.95% 6.56%", color: "#FFFFFF" }}>+ Set up showroom</p>
    </div>
  }

  if (props.appState === "meetings") {
    headerButton = <div className={cssClasses.scheduleMeetingButton} onClick={props.meetingClicked}>
      <p style={{ height: "57.14%", width: "86.84%", font: "normal normal bold 14px/16px Arial", margin: "3.95% 6.56% 3.95% 6.56%", color: "#FFFFFF" }}>+ Schedule Meeting</p>
    </div>
  }

  return <div className={cssClasses.Header}>
    <HeaderName saasToolName={props.saasToolName} />
    <div style={{ height: "100%", width: "14.4%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
      {headerButton}
    </div>
    <Profile name={props.name} />
  </div>;
};

const mapStateToProps = (state) => {
  return {
    appState: state.mainApp.appState,
  };
};

export default connect(mapStateToProps)(header);
