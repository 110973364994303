import React, { Component } from "react";
import cssClasses from "./BoothCreator.module.css";

import BoothTile from "../BoothTile/BoothTile";

import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

class BoothCreator extends Component {

  state = {
    selectionIndex: null
  }

  enableBoothCreator = (selectionIndex) => {
    this.props.onBoothEditing();
    this.setState({ selectionIndex: selectionIndex });
  }
  
  disableBoothCreator = () => {
    this.props.onUserDetails();
    this.props.onResetEditing();
    this.setState({ selectionIndex: null });
  }

  deleteTile = (index) => {
    if (window.confirm('Are you sure you wish to delete this booth?')) {

      let boothsArray = [];
      boothsArray = this.props.booths;

      let newBoothsArray = [];

      boothsArray.forEach(element => {
        if (element !== boothsArray[index]) {
          newBoothsArray.push(element);
        }
      });

      let body = {
        booths: newBoothsArray
      }
      this.props.onUpdateUserDetails(body);
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.interactionWithUnity);
    //   window.addEventListener('message', event => {
    //   // IMPORTANT: check the origin of the data! 
    //   // if (event.origin.startsWith('http://your-first-site.com')) {
    //   //   // The data was sent from your site.
    //   //   // Data sent with postMessage is stored in event.data:
    //   // console.log("Data: " + event.data);

    //   console.log("Event data recieved in react: " + event.data);

    //   switch (event.data) {
    //     case "Loaded": {

    //       const childFrameObj = document.getElementById('iframeBooth');
    //       childFrameObj.contentWindow.postMessage(localStorage.getItem("idToken").replace(/['"]+/g, ''), '*'); //window.postMessage
    //       childFrameObj.contentWindow.postMessage("selectionIndex:" + this.state.selectionIndex, '*'); //window.postMessage

    //       break;
    //     }
    //     case "Close": {
    //       console.log("Unity asked to close the iframe");
    //       this.disableBoothCreator();
    //       break;
    //     }
    //   }
    //   // } else {
    //   //   // The data was NOT sent from your site! 
    //   //   // Be careful! Do not use it. This else branch is
    //   //   // here just for clarity, you usually shouldn't need it.
    //   //   return;
    //   // }
    // });
  }

  interactionWithUnity = (event) => {
    console.log("Event data recieved in react: " + event.data);

    switch (event.data) {
      case "Loaded": {

        const childFrameObj = document.getElementById('iframeBooth');
        childFrameObj.contentWindow.postMessage(localStorage.getItem("idToken").replace(/['"]+/g, ''), '*'); //window.postMessage
        childFrameObj.contentWindow.postMessage("selectionIndex:" + this.state.selectionIndex, '*'); //window.postMessage
        break;
      }
      case "Save": {
        console.log("Unity asked to Save the iframe");
        if (this.props.boothEditing) {
          if (window.confirm('Are you sure you want to save the booth?')) {
            const childFrameObj = document.getElementById('iframeBooth');
            childFrameObj.contentWindow.postMessage("save", '*');
          }else{
            const childFrameObj = document.getElementById('iframeBooth');
            childFrameObj.contentWindow.postMessage("nos", '*');
          }
        }
        break;
      }
      case "Close": {
        console.log("Unity asked to close the iframe");
        this.disableBoothCreator();
        break;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.interactionWithUnity);
  }

  render() {
    const boothsArray = [];
    let boothsListContent = null;

    for (let key in this.props.booths) {
      boothsArray.push({
        id: key,
        data: this.props.booths[key],
      });
    }

    boothsListContent = boothsArray.map((booth, index) => (
      <BoothTile
        key={index}
        clicked={() => this.enableBoothCreator(index)}
        delete={() => this.deleteTile(index)}
        name={booth.data.name}
        screenshotLink={booth.data.screenshotLink}
      />
    ));

    let boothsList = <div className={cssClasses.BoothsListPanel}>
      <div className={cssClasses.NewBoothTile} onClick={() => this.enableBoothCreator(null)}>
        <p style={{ font: "normal normal bold 18px/21px Arial", color: "#000000" }}>Create New Booth (Without template)</p>
      </div>
      <div className={cssClasses.NewBoothTile} onClick={() => this.enableBoothCreator("template")}>
        <p style={{ font: "normal normal bold 18px/21px Arial", color: "#000000" }}>Create New Booth (With template)</p>
      </div>
      {boothsListContent}
    </div>;

    let frame = null;

    if (this.props.boothEditing) {
      frame = <iframe id="iframeBooth" title="BoothCreator" src="https://bc.d5k6k7gkse4he.amplifyapp.com/" style={{ height: "100%", width: "100%", display: "flex", margin: "0 0 0 0" }} frameBorder="0" scrolling="no"></iframe>
    }
    else {
      frame = boothsList
    }

    return (< div className={cssClasses.BoothCreator} >
      {frame}
    </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    booths: state.mainApp.userDetails.booths,
    boothEditing: state.mainApp.boothEditing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserDetails: () =>
      dispatch(actions.getUserDetails()),
    onUpdateUserDetails: (body) => {
      dispatch(actions.updateUserDetails(body))
    },
    onBoothEditing: () =>
      dispatch(actions.editBoothCreator()),
    onResetEditing: () =>
      dispatch(actions.resetEditing()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothCreator);