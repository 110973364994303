import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadTotalPresentationViewsInside = () => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE,
        error: false
    };
};

export const loadTotalPresentationViewsInsideSuccess = (totalPresentationViewsMeetingInside) => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_SUCCESS,
        error: false,
        totalPresentationViewsMeetingInside: totalPresentationViewsMeetingInside
    };
};

export const loadTotalPresentationViewsInsideFail = (message) => {
    return {
        type: actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_FAIL,
        error: true,
        message: message
    };
};

export const getTotalPresentationViewsInside= (adminUserId, meetingSubject) => {
    return (dispatch) => {
        dispatch(loadTotalPresentationViewsInside());

        let totalPresentationViewsUsers = [];
        let totalPresentationViewsDates = [];
        let totalPresentationViewsActive = [];
        let totalPresentationViewsMeetingInside = null;

        let data = {
            adminUserId: adminUserId,
            meetingSubject: meetingSubject
        }

        // Total presentation views
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/gettotalpresentationviewsinsidemeeting',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalPresentationViewsAnalyticsData) {
                    totalPresentationViewsUsers.push(dataObj);
                }

                for (const dailyUser of totalPresentationViewsUsers) {
                    totalPresentationViewsDates.push(dailyUser.date);
                    totalPresentationViewsActive.push(dailyUser.presentationViews);
                }

                let totalPresentationViewsObject = {
                    labels: totalPresentationViewsDates,
                    data: totalPresentationViewsActive
                }

                totalPresentationViewsMeetingInside = totalPresentationViewsObject;
                dispatch(loadTotalPresentationViewsInsideSuccess(totalPresentationViewsMeetingInside));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadTotalPresentationViewsInsideFail(error.response.data.error));
                }
            })
    }
};