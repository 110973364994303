import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./ShowroomComponent.module.css";

import * as actions from "../../../../store/actions/index";
import closeButton from "../../../../assets/images/meetingclose.png";

class ShowroomComponent extends Component {

    state = {
        showroomForm: {
            showroomName: "",
            virtualRoom: "",
            showroomURL: "",
            accessCode: "",
            showroomIndex: 0
        }
    }

    componentDidMount() {
        console.log("meeting component mounted");

        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: this.props.showroomName,
            virtualRoom: this.props.virtualRoom,
            showroomURL: this.props.showroomURL,
            accessCode: this.props.accessCode,
            showroomIndex: this.props.showroomIndex
        }
        this.setState({ showroomForm: updatedShowroomForm });
    }

    updateShowroom = () => {

        let showroomData = {
            showroomName: this.state.showroomForm.showroomName,
            virtualRoom: this.state.showroomForm.virtualRoom,
            showroomURL: this.state.showroomForm.showroomURL,
            accessCode: this.state.showroomForm.accessCode,
            showroomIndex: this.state.showroomForm.showroomIndex
        }
        console.log("data before sending: " + showroomData);

        this.props.onUpdateShowroomBox(true, showroomData);
    }

    joinShowroom = () => {
        window.open(this.props.showroomURL);
    }

    getOrdinal = (d) => {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    render() {

        return (
            <div className={cssClasses.ShowroomComponent}>
                <div style={{ height: "2.68%", width: "100%", margin: "0 0 4.27% 0", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                    <p style={{ height: "100%", width: "96.65%", margin: "0 0 0 0", font: "normal normal bold 16px/18px Arial" }}>Showroom</p>
                    <img className={cssClasses.CloseButton} src={closeButton} onClick={this.props.delete}></img>
                </div>
                <p className={cssClasses.TextHeading}>Subject</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.showroomName}</p>
                </div>
                <p className={cssClasses.TextHeading}>Virtual Room</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.virtualRoom}</p>
                </div>
                <p className={cssClasses.TextHeading}>Showroom URL</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.showroomURL}</p>
                </div>
                <p className={cssClasses.TextHeading}>Access Code</p>
                <div className={cssClasses.TextData}>
                    <p className={cssClasses.Text}>{this.props.accessCode}</p>
                </div>
                <div className={cssClasses.ShowroomActions}>
                    <button style={{ height: "100%", width: "19.51%", margin: "0 7.32% 0 0", border: "0", background: "#843D96 0% 0% no-repeat padding-box", font: "normal normal bold 14px/16px Arial", color: "#FFFFFF" }} onClick={this.joinShowroom}>Enter</button>
                    <button style={{ height: "100%", width: "19.51%", border: "1px solid #843D96", borderRadius: " 2px", font: "normal normal bold 14px/16px Arial", color: "#843D96" }} onClick={this.updateShowroom}>Modify</button>
                </div>
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateShowroomBox: (value, body) =>
            dispatch(actions.updateShowroomBox(value, body))
    };
};

export default connect(null, mapDispatchToProps)(ShowroomComponent);