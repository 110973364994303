import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadTotalVideoWatchTimeOutside = () => {
    return {
        type: actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE,
        error: false
    };
};

export const loadTotalVideoWatchTimeOutsideSuccess = (totalVideoMeetingOutside) => {
    return {
        type: actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE_SUCCESS,
        error: false,
        totalVideoMeetingOutside: totalVideoMeetingOutside
    };
};

export const loadTotalVideoWatchTimeOutsideFail = (message) => {
    return {
        type: actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE_FAIL,
        error: true,
        message: message
    };
};

export const getTotalVideoWatchTimeMeetingOutside = (adminUserId, meetingSubject) => {
    return (dispatch) => {
        dispatch(loadTotalVideoWatchTimeOutside());

        let totalVideoWatchTimeUsers = [];
        let totalVideoWatchTimeDates = [];
        let totalVideoWatchTimeActive = [];
        let totalVideoMeetingOutside = null;

        let data = {
            adminUserId: adminUserId,
            meetingSubject: meetingSubject
        }

        // Total video watch time
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/gettotalvideowatchtimeoutsidemeeting',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalVideoWatchTimeData) {
                    totalVideoWatchTimeUsers.push(dataObj);
                }

                for (const dailyUser of totalVideoWatchTimeUsers) {
                    totalVideoWatchTimeDates.push(dailyUser.date);
                    totalVideoWatchTimeActive.push(dailyUser.videoWatchTime);

                }

                let object = {
                    labels: totalVideoWatchTimeDates,
                    data: totalVideoWatchTimeActive
                }

                totalVideoMeetingOutside = object;
                dispatch(loadTotalVideoWatchTimeOutsideSuccess(totalVideoMeetingOutside));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadTotalVideoWatchTimeOutsideFail(error.response.data.error));
                }
            })
    }
};