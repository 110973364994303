import * as actionTypes from "./actionTypes";

export const enableShowrooms = () => {
    return {
        type: actionTypes.SHOWROOMS
    };
};

export const enableSetUpShowroomBox = () => {
    return {
        type: actionTypes.ENABLE_SET_UP_SHOWROOM_BOX
    };
};

export const disableSetUpShowroomBox = () => {
    return {
        type: actionTypes.DISABLE_SET_UP_SHOWROOM_BOX
    };
};

export const setUpShowroomReset = () => {
    return {
        type: actionTypes.SET_UP_SHOWROOM_STATE_RESET
    };
};

export const enableUpdateShowroomBox = (data) => {
    return {
        type: actionTypes.ENABLE_UPDATE_SHOWROOM_BOX,
        showroomData: data
    };
};

export const disableUpdateShowroomBox = () => {
    return {
        type: actionTypes.DISABLE_UPDATE_SHOWROOM_BOX
    };
};

export const updateShowroomReset = () => {
    return {
        type: actionTypes.UPDATE_SHOWROOM_STATE_RESET
    };
};

export const showrooms = () => {
    return (dispatch) => {
        dispatch(enableShowrooms());
    }
};

export const setUpShowroomBox = (value) => {
    return (dispatch) => {
        if (value) {
            dispatch(enableSetUpShowroomBox());
        }
        else {
            dispatch(setUpShowroomReset());
            dispatch(disableSetUpShowroomBox());
        }
    }
};

export const updateShowroomBox = (value, data) => {
    return (dispatch) => {
        if (value) {
            dispatch(enableUpdateShowroomBox(data));
        }
        else {
            dispatch(updateShowroomReset());
            dispatch(disableUpdateShowroomBox());
        }
    }
};