import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadDailyMeetings = () => {
    return {
        type: actionTypes.DAILY_MEETINGS,
        error: false
    };
};

export const loadDailyMeetingsSuccess = (dailyMeetings) => {
    return {
        type: actionTypes.DAILY_MEETINGS_SUCCESS,
        error: false,
        dailyMeetings: dailyMeetings
    };
};

export const loadDailyMeetingsFail = (message) => {
    return {
        type: actionTypes.DAILY_MEETINGS_FAIL,
        error: true,
        message: message
    };
};

export const getDailyMeetings = (adminUserId, meetingSubject) => {
    return (dispatch) => {
        dispatch(loadDailyMeetings());

        let dailyUsers = [];
        let dailyUsersDates = [];
        let dailyUsersActive = [];
        let dailyMeetings = null;

        let data = {
            adminUserId: adminUserId,
            meetingSubject: meetingSubject
        }

        // Daily Users
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/getdailymeetings',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.dailyMeetingsAnalyticsData) {
                    dailyUsers.push(dataObj);
                }
                for (const dailyUser of dailyUsers) {
                    dailyUsersDates.push(dailyUser.date);
                    dailyUsersActive.push(dailyUser.users);
                }
                let dailyUsersObject = {
                    labels: dailyUsersDates,
                    data: dailyUsersActive
                }

                dailyMeetings = dailyUsersObject;
                dispatch(loadDailyMeetingsSuccess(dailyMeetings));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadDailyMeetingsFail(error.response.data.error));
                }
            })
    }
};