import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    loadingDailyUserShowroom: true,
    dailyUsersShowroom: null,
    loadingTotalPresentationViewsShowroom: true,
    totalPresentationViewsShowroom: null,
    loadingTotalVideoWatchTimeShowroom: true,
    totalVideoWatchTimeShowroom: null,
    loadingTotalTimeSpentShowroom: true,
    totalTimeSpentShowroom: null,
    error: null,
    message: "",
};

const loadDailyUsersShowroom = (state, action) => {
    return updateObject(state, {
        loadingDailyUserShowroom: true,
        error: false,
        message: ""
    });
};

const loadDailyUsersShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loadingDailyUserShowroom: false,
        error: false,
        message: "",
        dailyUsersShowroom: action.dailyUsersShowroom
    });
};

const loadDailyUsersShowroomFail = (state, action) => {
    return updateObject(state, {
        loadingDailyUserShowroom: false,
        error: true,
        message: action.message,
        dailyUsersShowroom: null
    });
};

const loadTotalPresentationViewsShowroom = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsShowroom: true,
        error: false,
        message: ""
    });
};

const loadTotalPresentationViewsShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsShowroom: false,
        error: false,
        message: "",
        totalPresentationViewsShowroom: action.totalPresentationViewsShowroom
    });
};

const loadTotalPresentationViewsShowroomFail = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsShowroom: false,
        error: true,
        message: action.message,
        totalPresentationViewsShowroom: null
    });
};

const loadTotalVideoWatchTimeShowroom = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoWatchTimeShowroom: true,
        error: false,
        message: ""
    });
};

const loadTotalVideoWatchTimeShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoWatchTimeShowroom: false,
        error: false,
        message: "",
        totalVideoWatchTimeShowroom: action.totalVideoWatchTimeShowroom
    });
};

const loadTotalVideoWatchTimeShowroomFail = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoWatchTimeShowroom: false,
        error: true,
        message: action.message,
        totalVideoWatchTimeShowroom: null
    });
};

const loadTotalTimeSpentShowroom = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentShowroom: true,
        error: false,
        message: ""
    });
};

const loadTotalTimeSpentShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentShowroom: false,
        error: false,
        message: "",
        totalTimeSpentShowroom: action.totalTimeSpentShowroom
    });
};

const loadTotalTimeSpentShowroomFail = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentShowroom: false,
        error: true,
        message: action.message,
        totalTimeSpentShowroom: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DAILY_USERS_SHOWROOM:
            return loadDailyUsersShowroom(state, action);

        case actionTypes.DAILY_USERS_SHOWROOM_SUCCESS:
            return loadDailyUsersShowroomSuccess(state, action);

        case actionTypes.DAILY_USERS_SHOWROOM_FAIL:
            return loadDailyUsersShowroomFail(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM:
            return loadTotalPresentationViewsShowroom(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM_SUCCESS:
            return loadTotalPresentationViewsShowroomSuccess(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_SHOWROOM_FAIL:
            return loadTotalPresentationViewsShowroomFail(state, action);

        case actionTypes.TOTAL_VIDEO_SHOWROOM:
            return loadTotalVideoWatchTimeShowroom(state, action);

        case actionTypes.TOTAL_VIDEO_SHOWROOM_SUCCESS:
            return loadTotalVideoWatchTimeShowroomSuccess(state, action);

        case actionTypes.TOTAL_VIDEO_SHOWROOM_FAIL:
            return loadTotalVideoWatchTimeShowroomFail(state, action);

        case actionTypes.TOTAL_TIME_SPENT_SHOWROOM:
            return loadTotalTimeSpentShowroom(state, action);

        case actionTypes.TOTAL_TIME_SPENT_SHOWROOM_SUCCESS:
            return loadTotalTimeSpentShowroomSuccess(state, action);

        case actionTypes.TOTAL_TIME_SPENT_SHOWROOM_FAIL:
            return loadTotalTimeSpentShowroomFail(state, action);

        default:
            return state;
    }
};

export default reducer;