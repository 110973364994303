import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    loadingDailyUserMeeting: true,
    dailyUsersMeeting: null,
    loadingTotalPresentationViewsMeetingOutside: true,
    totalPresentationViewsMeetingOutside: null,
    loadingTotalPresentationViewsMeetingInside: true,
    totalPresentationViewsMeetingInside: null,
    loadingTotalVideoMeetingOutside: true,
    totalVideoMeetingOutside: null,
    loadingTotalVideoMeetingInside: true,
    totalVideoMeetingInside: null,
    loadingDailyMeetings: true,
    dailyMeetings: null,
    loadingDailyMeetingsDuration: true,
    dailyMeetingsDuration: null,
    loadingTotalTimeSpentMeeting: true,
    totalTimeSpentMeeting: null,
    error: null,
    message: "",
};

const loadDailyUsersMeeting = (state, action) => {
    return updateObject(state, {
        loadingDailyUserMeeting: true,
        error: false,
        message: ""
    });
};

const loadDailyUsersMeetingSuccess = (state, action) => {
    return updateObject(state, {
        loadingDailyUserMeeting: false,
        error: false,
        message: "",
        dailyUsersMeeting: action.dailyUsersMeeting
    });
};

const loadDailyUsersMeetingFail = (state, action) => {
    return updateObject(state, {
        loadingDailyUserMeeting: false,
        error: true,
        message: action.message,
        dailyUsersMeeting: null
    });
};

const loadTotalPresentationViewsMeetingOutside = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingOutside: true,
        error: false,
        message: ""
    });
};

const loadTotalPresentationViewsMeetingOutsideSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingOutside: false,
        error: false,
        message: "",
        totalPresentationViewsMeetingOutside: action.totalPresentationViewsMeetingOutside
    });
};

const loadTotalPresentationViewsMeetingOutsideFail = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingOutside: false,
        error: true,
        message: action.message,
        totalPresentationViewsMeetingOutside: null
    });
};

const loadTotalPresentationViewsMeetingInside = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingInside: true,
        error: false,
        message: ""
    });
};

const loadTotalPresentationViewsMeetingInsideSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingInside: false,
        error: false,
        message: "",
        totalPresentationViewsMeetingInside: action.totalPresentationViewsMeetingInside
    });
};

const loadTotalPresentationViewsMeetingInsideFail = (state, action) => {
    return updateObject(state, {
        loadingTotalPresentationViewsMeetingInside: false,
        error: true,
        message: action.message,
        totalPresentationViewsMeetingInside: null
    });
};

const loadTotalVideoMeetingOutside = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingOutside: true,
        error: false,
        message: ""
    });
};

const loadTotalVideoMeetingOutsideSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingOutside: false,
        error: false,
        message: "",
        totalVideoMeetingOutside: action.totalVideoMeetingOutside
    });
};

const loadTotalVideoMeetingOutsideFail = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingOutside: false,
        error: true,
        message: action.message,
        totalVideoMeetingOutside: null
    });
};

const loadTotalVideoMeetingInside = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingInside: true,
        error: false,
        message: ""
    });
};

const loadTotalVideoMeetingInsideSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingInside: false,
        error: false,
        message: "",
        totalVideoMeetingInside: action.totalVideoMeetingInside
    });
};

const loadTotalVideoMeetingInsideFail = (state, action) => {
    return updateObject(state, {
        loadingTotalVideoMeetingInside: false,
        error: true,
        message: action.message,
        totalVideoMeetingInside: null
    });
};

const loadDailyMeetings = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetings: true,
        error: false,
        message: ""
    });
};

const loadDailyMeetingsSuccess = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetings: false,
        error: false,
        message: "",
        dailyMeetings: action.dailyMeetings
    });
};

const loadDailyMeetingsFail = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetings: false,
        error: true,
        message: action.message,
        dailyMeetings: null
    });
};

const loadDailyMeetingsDuration = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetingsDuration: true,
        error: false,
        message: ""
    });
};

const loadDailyMeetingsDurationSuccess = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetingsDuration: false,
        error: false,
        message: "",
        dailyMeetingsDuration: action.dailyMeetingsDuration
    });
};

const loadDailyMeetingsDurationFail = (state, action) => {
    return updateObject(state, {
        loadingDailyMeetingsDuration: false,
        error: true,
        message: action.message,
        dailyMeetingsDuration: null
    });
};

const loadTotalTimeSpentMeeting = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentMeeting: true,
        error: false,
        message: ""
    });
};

const loadTotalTimeSpentMeetingSuccess = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentMeeting: false,
        error: false,
        message: "",
        totalTimeSpentMeeting: action.totalTimeSpentMeeting
    });
};

const loadTotalTimeSpentMeetingFail = (state, action) => {
    return updateObject(state, {
        loadingTotalTimeSpentMeeting: false,
        error: true,
        message: action.message,
        totalTimeSpentMeeting: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DAILY_USERS_MEETING:
            return loadDailyUsersMeeting(state, action);

        case actionTypes.DAILY_USERS_MEETING_SUCCESS:
            return loadDailyUsersMeetingSuccess(state, action);

        case actionTypes.DAILY_USERS_MEETING_FAIL:
            return loadDailyUsersMeetingFail(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE:
            return loadTotalPresentationViewsMeetingOutside(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_SUCCESS:
            return loadTotalPresentationViewsMeetingOutsideSuccess(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_OUTSIDE_FAIL:
            return loadTotalPresentationViewsMeetingOutsideFail(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE:
            return loadTotalPresentationViewsMeetingInside(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_SUCCESS:
            return loadTotalPresentationViewsMeetingInsideSuccess(state, action);

        case actionTypes.TOTAL_PRESENTATION_VIEWS_MEETING_INSIDE_FAIL:
            return loadTotalPresentationViewsMeetingInsideFail(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE:
            return loadTotalVideoMeetingOutside(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE_SUCCESS:
            return loadTotalVideoMeetingOutsideSuccess(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_OUTSIDE_FAIL:
            return loadTotalVideoMeetingOutsideFail(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_INSIDE:
            return loadTotalVideoMeetingInside(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_INSIDE_SUCCESS:
            return loadTotalVideoMeetingInsideSuccess(state, action);

        case actionTypes.TOTAL_VIDEO_MEETING_INSIDE_FAIL:
            return loadTotalVideoMeetingInsideFail(state, action);

        case actionTypes.DAILY_MEETINGS:
            return loadDailyMeetings(state, action);

        case actionTypes.DAILY_MEETINGS_SUCCESS:
            return loadDailyMeetingsSuccess(state, action);

        case actionTypes.DAILY_MEETINGS_FAIL:
            return loadDailyMeetingsFail(state, action);

        case actionTypes.DAILY_MEETINGS_DURATION:
            return loadDailyMeetingsDuration(state, action);

        case actionTypes.DAILY_MEETINGS_DURATION_SUCCESS:
            return loadDailyMeetingsDurationSuccess(state, action);

        case actionTypes.DAILY_MEETINGS_DURATION_FAIL:
            return loadDailyMeetingsDurationFail(state, action);

        case actionTypes.TOTAL_TIME_SPENT_MEETING:
            return loadTotalTimeSpentMeeting(state, action);

        case actionTypes.TOTAL_TIME_SPENT_MEETING_SUCCESS:
            return loadTotalTimeSpentMeetingSuccess(state, action);

        case actionTypes.TOTAL_TIME_SPENT_MEETING_FAIL:
            return loadTotalTimeSpentMeetingFail(state, action);

        default:
            return state;
    }
};

export default reducer;