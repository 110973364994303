import * as actionTypes from "./actionTypes";
import axios from "axios";

export const enableAnalytics = () => {
    return {
        type: actionTypes.ANALYTICS
    };
};

export const enableShowroomDashboard = () => {
    return {
        type: actionTypes.SHOWROOM_DASHBOARD,
        error: false
    };
};

export const enableMeetingDashboard = () => {
    return {
        type: actionTypes.MEETING_DASHBOARD
    };
};

export const resetDashboard = () => {
    return {
        type: actionTypes.RESET_ANALYTICS
    };
};

export const analytics = () => {
    return (dispatch) => {
        dispatch(enableAnalytics());
    }
};

export const enableShowroomAnalytics = (adminUserId, showroomName) => {
    return (dispatch) => {
        dispatch(enableShowroomDashboard());
    }
};

export const enableMeetingAnalytics = () => {
    return (dispatch) => {
        dispatch(enableMeetingDashboard());
    }
};

export const resetAnalytics = () => {
    return (dispatch) => {
        dispatch(resetDashboard());
    }
};