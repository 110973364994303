import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./AnalyticsTile.module.css";

import { withRouter } from "react-router-dom";

class AnalyticsTile extends Component {

    state = {
        imageUrl:""
    }

    componentDidMount() {
        for (let index = 0; index < this.props.rooms.length; index++) {
            const fetchedRoomName = this.props.rooms[index].name;
            if (this.props.room == fetchedRoomName) {
                this.setState({ imageUrl: this.props.rooms[index].screenshotLink });
            }
        }
    }

    render() {
        return (
            <div className={cssClasses.AnalyticsTile}>
                <div className={cssClasses.AnalyticsTileHeader}>
                    <p style={{ width: "100%", height: "100%", margin: "0 0 0 0", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {this.props.name}
                    </p>
                </div>
                <div className={cssClasses.AnalyticsTileImage} onClick={this.props.clicked}>
                    <img src={this.state.imageUrl} style={{ height: "100%", width: "100%" }} />
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rooms: state.mainApp.userDetails.rooms
    };
};

export default withRouter(connect(mapStateToProps)(AnalyticsTile));