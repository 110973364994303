import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    userDetails: null,
    userDetailsFetched: false,
    error: null,
    message: "",
    appState: "",
    saasToolName: "Booth Creator",
    setUpShowroomBox: false,
    updateShowroomBox: false,
    setUpShowroom: false,
    updateShowroom: false,
    updateShowroomData: "",
    scheduleMeetingBox: false,
    updateMeetingBox: false,
    scheduleMeeting: false,
    updateMeeting: false,
    updateMeetingData: "",
    loading: false,
    newShowroomIndex: 0,
    newMeetingIndex: 0,
    boothEditing: false,
    roomEditing: false,
    showroomDashboard: false,
    meetingDashboard: false
};

const getUserDetails = (state, action) => {
    return updateObject(state, {
        userDetails: null,
        userDetailsFetched: false
    });
};

const getUserDetailsSuccess = (state, action) => {
    return updateObject(state, {
        userDetails: action.userDetails,
        userDetailsFetched: true
    });
};

const getUserDetailsFail = (state, action) => {
    return updateObject(state, {
        userDetails: null,
        userDetailsFetched: false,
        message: action.message
    });
};

const getBoothCreator = (state, action) => {
    return updateObject(state, {
        appState: "boothCreator",
        saasToolName: "Booth Creator",
        boothEditing: false,
        roomEditing: false
    });
};

const getRoomCreator = (state, action) => {
    return updateObject(state, {
        appState: "roomCreator",
        saasToolName: "Room Creator",
        boothEditing: false,
        roomEditing: false
    });
};

//Showrooms

const getShowrooms = (state, action) => {
    return updateObject(state, {
        appState: "showrooms",
        saasToolName: "Showrooms",
        boothEditing: false,
        roomEditing: false
    });
};
const enableSetUpShowroomBox = (state, action) => {
    return updateObject(state, {
        setUpShowroomBox: true,
        setUpShowroom: false
    });
};

const disableSetUpShowroomBox = (state, action) => {
    return updateObject(state, {
        setUpShowroomBox: false
    });
};

const SetUpShowroom = (state, action) => {
    return updateObject(state, {
        loading: true,
        setUpShowroom: false,
        error: false,
        message: ""
    });
};

const SetUpShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        setUpShowroom: true,
        error: false,
        message: action.message
    });
};

const SetUpShowroomFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        setUpShowroom: false,
        error: true,
        message: action.message
    });
};
const SetUpShowroomStateReset = (state, action) => {
    return updateObject(state, {
        loading: false,
        setUpShowroomBox: false,
        setUpShowroom: false,
        error: false
    });
};

const SetUpShowroomUpdateIndex = (state, action) => {
    return updateObject(state, {
        newShowroomIndex: action.newShowroomIndex
    });
};

const enableUpdateShowroomBox = (state, action) => {
    return updateObject(state, {
        updateShowroomBox: true,
        updateShowroomData: action.showroomData
    });
};

const disableUpdateShowroomBox = (state, action) => {
    return updateObject(state, {
        updateShowroomBox: false
    });
};

const updateShowroom = (state) => {
    return updateObject(state, {
        loading: true,
        updateShowroom: false,
        error: false,
        message: ""
    });
};

const updateShowroomSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateShowroom: true,
        error: false,
        message: action.message
    });
};

const updateShowroomFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateShowroom: false,
        error: true,
        message: action.message
    });
};

const updateShowroomReset = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateShowroomBox: false,
        updateShowroom: false,
        error: false
    });
};


//Meetings

const getMeetingsPage = (state, action) => {
    return updateObject(state, {
        appState: "meetings",
        saasToolName: "Meetings"
    });
};

const enableScheduleMeetingBox = (state, action) => {
    return updateObject(state, {
        scheduleMeetingBox: true,
        scheduleMeeting: false
    });
};

const disableScheduleMeetingBox = (state, action) => {
    return updateObject(state, {
        scheduleMeetingBox: false
    });
};

const scheduleMeeting = (state, action) => {
    return updateObject(state, {
        loading: true,
        scheduleMeeting: false,
        error: false,
        message: ""
    });
};

const scheduleMeetingSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        scheduleMeeting: true,
        error: false,
        message: action.message
    });
};

const scheduleMeetingFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        scheduleMeeting: false,
        error: true,
        message: action.message
    });
};
const scheduleMeetingStateReset = (state, action) => {
    return updateObject(state, {
        loading: false,
        scheduleMeetingBox: false,
        scheduleMeeting: false,
        error: false
    });
};

const scheduleMeetingUpdateIndex = (state, action) => {
    return updateObject(state, {
        newMeetingIndex: action.newMeetingIndex
    });
};

const enableUpdateMeetingBox = (state, action) => {
    return updateObject(state, {
        updateMeetingBox: true,
        updateMeetingData: action.meetingData
    });
};

const disableUpdateMeetingBox = (state, action) => {
    return updateObject(state, {
        updateMeetingBox: false
    });
};

const updateMeeting = (state) => {
    return updateObject(state, {
        loading: true,
        updateMeeting: false,
        error: false,
        message: ""
    });
};

const updateMeetingSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateMeeting: true,
        error: false,
        message: action.message
    });
};

const updateMeetingFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateMeeting: false,
        error: true,
        message: action.message
    });
};

const updateMeetingStateReset = (state, action) => {
    return updateObject(state, {
        loading: false,
        updateMeetingBox: false,
        updateMeeting: false,
        error: false
    });
};

const getAnalytics = (state, action) => {
    return updateObject(state, {
        appState: "analytics",
        saasToolName: "Analytics"
    });
};

const enableShowroomDashboard = (state, action) => {
    return updateObject(state, {
        showroomDashboard: true,
        meetingDashboard: false
    });
};

const enableMeetingDashboard = (state, action) => {
    return updateObject(state, {
        showroomDashboard: false,
        meetingDashboard: true
    });
};
const resetAnalytics = (state, action) => {
    return updateObject(state, {
        showroomDashboard: false,
        meetingDashboard: false
    });
};

const enableBoothEditing = (state, action) => {
    return updateObject(state, {
        boothEditing: true,
        roomEditing: false
    });
};

const enableRoomEditing = (state, action) => {
    return updateObject(state, {
        boothEditing: false,
        roomEditing: true
    });
};

const resetEditing = (state, action) => {
    return updateObject(state, {
        boothEditing: false,
        roomEditing: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_DETAILS:
            return getUserDetails(state, action);

        case actionTypes.GET_USER_DETAILS_SUCCESS:
            return getUserDetailsSuccess(state, action);

        case actionTypes.GET_USER_DETAILS_FAIL:
            return getUserDetailsFail(state, action);

        case actionTypes.BOOTH_CREATOR:
            return getBoothCreator(state, action);

        case actionTypes.ROOM_CREATOR:
            return getRoomCreator(state, action);

        //Showrooms

        case actionTypes.SHOWROOMS:
            return getShowrooms(state, action);

        case actionTypes.ENABLE_SET_UP_SHOWROOM_BOX:
            return enableSetUpShowroomBox(state, action);

        case actionTypes.DISABLE_SET_UP_SHOWROOM_BOX:
            return disableSetUpShowroomBox(state, action);

        case actionTypes.SET_UP_SHOWROOM:
            return SetUpShowroom(state, action);

        case actionTypes.SET_UP_SHOWROOM_SUCCESS:
            return SetUpShowroomSuccess(state, action);

        case actionTypes.SET_UP_SHOWROOM_FAIL:
            return SetUpShowroomFail(state, action);

        case actionTypes.SET_UP_SHOWROOM_STATE_RESET:
            return SetUpShowroomStateReset(state, action);

        case actionTypes.SET_UP_SHOWROOM_UPDATE_INDEX:
            return SetUpShowroomUpdateIndex(state, action);

        case actionTypes.ENABLE_UPDATE_SHOWROOM_BOX:
            return enableUpdateShowroomBox(state, action);

        case actionTypes.DISABLE_UPDATE_SHOWROOM_BOX:
            return disableUpdateShowroomBox(state, action);

        case actionTypes.UPDATE_SHOWROOM:
            return updateShowroom(state, action);

        case actionTypes.UPDATE_SHOWROOM_SUCCESS:
            return updateShowroomSuccess(state, action);

        case actionTypes.UPDATE_SHOWROOM_FAIL:
            return updateShowroomFail(state, action);

        case actionTypes.UPDATE_SHOWROOM_STATE_RESET:
            return updateShowroomReset(state, action);

        //Meetings

        case actionTypes.MEETINGS:
            return getMeetingsPage(state, action);

        case actionTypes.ENABLE_SCHEDULE_MEETING_BOX:
            return enableScheduleMeetingBox(state, action);

        case actionTypes.DISABLE_SCHEDULE_MEETING_BOX:
            return disableScheduleMeetingBox(state, action);

        case actionTypes.SCHEDULE_MEETING:
            return scheduleMeeting(state, action);

        case actionTypes.SCHEDULE_MEETING_SUCCESS:
            return scheduleMeetingSuccess(state, action);

        case actionTypes.SCHEDULE_MEETING_FAIL:
            return scheduleMeetingFail(state, action);

        case actionTypes.SCHEDULE_MEETING_STATE_RESET:
            return scheduleMeetingStateReset(state, action);

        case actionTypes.SCHEDULE_MEETING_UPDATE_INDEX:
            return scheduleMeetingUpdateIndex(state, action);

        case actionTypes.ENABLE_UPDATE_MEETING_BOX:
            return enableUpdateMeetingBox(state, action);

        case actionTypes.DISABLE_UPDATE_MEETING_BOX:
            return disableUpdateMeetingBox(state, action);

        case actionTypes.UPDATE_MEETING:
            return updateMeeting(state, action);

        case actionTypes.UPDATE_MEETING_SUCCESS:
            return updateMeetingSuccess(state, action);

        case actionTypes.UPDATE_MEETING_FAIL:
            return updateMeetingFail(state, action);

        case actionTypes.UPDATE_MEETING_STATE_RESET:
            return updateMeetingStateReset(state, action);

        case actionTypes.ANALYTICS:
            return getAnalytics(state, action);

        case actionTypes.SHOWROOM_DASHBOARD:
            return enableShowroomDashboard(state, action);

        case actionTypes.MEETING_DASHBOARD:
            return enableMeetingDashboard(state, action);

        case actionTypes.RESET_ANALYTICS:
            return resetAnalytics(state, action);

        case actionTypes.EDIT_BOOTH:
            return enableBoothEditing(state, action);

        case actionTypes.EDIT_ROOM:
            return enableRoomEditing(state, action);

        case actionTypes.RESET_EDITING:
            return resetEditing(state, action);

        default:
            return state;
    }
};

export default reducer;