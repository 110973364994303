import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./Analytics.module.css";
import AnalyticsTile from "./AnalyticsTile/AnalyticsTile";

import MeetingAnalytics from "./MeetingAnalytics/MeetingAnalytics";
import ShowroomAnalytics from "./ShowroomAnalytics/ShowroomAnalytics";

import { withRouter } from "react-router-dom";
import * as actions from "../../../store/actions/index";

class Analytics extends Component {

    // state = {
    //     showroomName: "",
    //     meetingName: ""
    // }

    onShowroomSelection = (adminUserId, showroomName) => {
        this.props.onAnalyticsShowroomSelection();
        this.props.onLoadDailyUsersShowroom(adminUserId, showroomName);
        this.props.onLoadTotalPresentationViewsShowroom(adminUserId, showroomName);
        this.props.onLoadTotalVideoWatchTimeShowroom(adminUserId, showroomName);
        this.props.onLoadTotalTimeSpentShowroom(adminUserId, showroomName);
        // this.setState({ showroomName: showroomName });
    }

    onMeetingSelection = (adminUserId, meetingSubject) => {
        this.props.onAnalyticsMeetingSelection();
        this.props.onLoadDailyUsersMeeting(adminUserId, meetingSubject);
        this.props.onLoadTotalPresentationViewsMeetingOutside(adminUserId, meetingSubject);
        this.props.onLoadTotalPresentationViewsMeetingInside(adminUserId, meetingSubject);
        this.props.onLoadTotalVideoWatchTimeMeetingOutside(adminUserId, meetingSubject);
        this.props.onLoadTotalVideoWatchTimeMeetingInside(adminUserId, meetingSubject);
        this.props.onLoadDailyUserMeetings(adminUserId, meetingSubject);
        this.props.onLoadDailyUserMeetingsDuration(adminUserId, meetingSubject);
        this.props.onLoadTotalTimeSpentMeeting(adminUserId, meetingSubject);
        // this.setState({ meetingName: meetingName });
    }

    render() {
        let showrooms = <h1>No showrooms available yet.</h1>;

        const showroomsArray = [];

        for (let key in this.props.showrooms) {
            showroomsArray.push({
                id: key,
                data: this.props.showrooms[key],
            });
        }

        showrooms = showroomsArray.map((showroom, index) => (
            <AnalyticsTile
                key={showroom.data.id}
                name={showroom.data.showroomName}
                room={showroom.data.virtualRoom}
                clicked={() => this.onShowroomSelection(this.props.adminUserId, showroom.data.showroomName)}
            ></AnalyticsTile>
        ));

        let meetings = <h1>No meetings available yet.</h1>;

        const meetingsArray = [];

        for (let key in this.props.meetings) {
            meetingsArray.push({
                id: key,
                data: this.props.meetings[key],
            });
        }

        meetings = meetingsArray.map((meeting, index) => (
            <AnalyticsTile
                key={meeting.data.id}
                name={meeting.data.subject}
                room={meeting.data.virtualRoom}
                clicked={() => this.onMeetingSelection(this.props.adminUserId,meeting.data.subject)}
            ></AnalyticsTile>
        ));

        let analyticsComponent = <div className={cssClasses.AnalyticsDashboard}>
            <div className={cssClasses.HeadingText}>
                Showrooms
            </div>
            <div className={cssClasses.AnalyticsComponent}>
                {showrooms}
            </div>
            <div className={cssClasses.HeadingText}>
                Meetings
            </div>
            <div className={cssClasses.AnalyticsComponent}>
                {meetings}
            </div>
        </div>;

        if (this.props.showroomDashboard) {
            analyticsComponent = <ShowroomAnalytics />
        } else if (this.props.meetingDashboard) {
            analyticsComponent = <MeetingAnalytics />
        }

        return (
            <div className={cssClasses.AnalyticsMain}>
                {analyticsComponent}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showrooms: state.mainApp.userDetails.showrooms,
        rooms: state.mainApp.userDetails.rooms,
        meetings: state.mainApp.userDetails.meetings,
        showroomDashboard: state.mainApp.showroomDashboard,
        meetingDashboard: state.mainApp.meetingDashboard,
        adminUserId: state.mainApp.userDetails.userId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAnalyticsShowroomSelection: () =>
            dispatch(actions.enableShowroomAnalytics()),
        onLoadDailyUsersShowroom: (adminUserId, showroomName) =>
            dispatch(actions.getDailyUsersShowroom(adminUserId, showroomName)),
        onLoadTotalPresentationViewsShowroom: (adminUserId, showroomName) =>
            dispatch(actions.getTotalPresentationViewsShowroom(adminUserId, showroomName)),
        onLoadTotalVideoWatchTimeShowroom: (adminUserId, showroomName) =>
            dispatch(actions.getTotalVideoWatchTimeShowroom(adminUserId, showroomName)),
        onLoadTotalTimeSpentShowroom: (adminUserId, showroomName) =>
            dispatch(actions.getTotalUserTimeSpentShowroom(adminUserId, showroomName)),
        onAnalyticsMeetingSelection: () =>
            dispatch(actions.enableMeetingAnalytics()),
        onLoadDailyUsersMeeting: (adminUserId, meetingSubject) =>
            dispatch(actions.getDailyUsersMeeting(adminUserId, meetingSubject)),
        onLoadTotalPresentationViewsMeetingOutside: (adminUserId, meetingSubject) =>
            dispatch(actions.getTotalPresentationViewsOutside(adminUserId, meetingSubject)),
        onLoadTotalPresentationViewsMeetingInside: (adminUserId, meetingSubject) =>
            dispatch(actions.getTotalPresentationViewsInside(adminUserId, meetingSubject)),
        onLoadTotalVideoWatchTimeMeetingOutside: (adminUserId, meetingSubject) =>
            dispatch(actions.getTotalVideoWatchTimeMeetingOutside(adminUserId, meetingSubject)),
        onLoadTotalVideoWatchTimeMeetingInside: (adminUserId, meetingSubject) =>
            dispatch(actions.getTotalVideoWatchTimeMeetingInside(adminUserId, meetingSubject)),
        onLoadDailyUserMeetings: (adminUserId, meetingSubject) =>
            dispatch(actions.getDailyMeetings(adminUserId, meetingSubject)),
        onLoadDailyUserMeetingsDuration: (adminUserId, meetingSubject) =>
            dispatch(actions.getDailyMeetingsDuration(adminUserId, meetingSubject)),
        onLoadTotalTimeSpentMeeting: (adminUserId, meetingSubject) =>
            dispatch(actions.getTotalUserTimeSpentMeeting(adminUserId, meetingSubject)),
        onResetSelection: () =>
            dispatch(actions.resetAnalytics()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Analytics));