import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadTotalVideoWatchTime = () => {
    return {
        type: actionTypes.TOTAL_VIDEO_SHOWROOM,
        error: false
    };
};

export const loadTotalVideoWatchTimeSuccess = (totalVideoWatchTimeShowroom) => {
    return {
        type: actionTypes.TOTAL_VIDEO_SHOWROOM_SUCCESS,
        error: false,
        totalVideoWatchTimeShowroom: totalVideoWatchTimeShowroom
    };
};

export const loadTotalVideoWatchTimeFail = (message) => {
    return {
        type: actionTypes.TOTAL_VIDEO_SHOWROOM_FAIL,
        error: true,
        message: message
    };
};

export const getTotalVideoWatchTimeShowroom = (adminUserId, showroomName) => {
    return (dispatch) => {
        dispatch(loadTotalVideoWatchTime());

        let totalVideoWatchTimeUsers = [];
        let totalVideoWatchTimeDates = [];
        let totalVideoWatchTimeActive = [];
        let totalVideoWatchTimeShowroom = null;

        let data = {
            adminUserId: adminUserId,
            showroomName: showroomName
        }

        // Total video watch time
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/gettotalvideowatchtimeshowroom',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.totalVideoWatchTimeData) {
                    totalVideoWatchTimeUsers.push(dataObj);
                }

                for (const dailyUser of totalVideoWatchTimeUsers) {
                    totalVideoWatchTimeDates.push(dailyUser.date);
                    totalVideoWatchTimeActive.push(dailyUser.videoWatchTime);

                }

                let object = {
                    labels: totalVideoWatchTimeDates,
                    data: totalVideoWatchTimeActive
                }

                totalVideoWatchTimeShowroom = object;
                dispatch(loadTotalVideoWatchTimeSuccess(totalVideoWatchTimeShowroom));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadTotalVideoWatchTimeFail(error.response.data.error));
                }
            })
    }
};