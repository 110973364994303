import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./ShowroomAnalytics.module.css";

import LoadingSpinner from "../../../UI/LoadingSpinner/LoadingSpinner";

import { withRouter } from "react-router-dom";
import GraphComponent from "../../../UI/GraphComponent/GraphComponent";

let usersData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Active Users (Daily)',
            data: [35, 39, 22, 9, 23, 45],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

let videoData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total video watch time (Daily)',
            data: [2, 18, 31, 41, 23, 25],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

let presentationData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Presentation Views (Daily)',
            data: [31, 21, 42, 17, 36, 7],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

let timeSpentData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total time spent by user (Daily)',
            data: [31, 21, 42, 17, 36, 7],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

let dataOptions = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

class ShowroomAnalytics extends Component {
    render() {

        let dailyUsersGraph = null;
        let totalPresentationViewsGraph = null;
        let totalVideoWatchTimeGraph = null;
        let totalTimeSpentGraph = null;

        if (this.props.loadingDailyUserShowroom) {
            dailyUsersGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                dailyUsersGraph = <GraphComponent
                    graph={usersData}
                    labelsValue={this.props.dailyUsersShowroom.labels}
                    dataValue={this.props.dailyUsersShowroom.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                dailyUsersGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalPresentationViewsShowroom) {
            totalPresentationViewsGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        } else {
            if (this.props.message === "") {
                totalPresentationViewsGraph = <GraphComponent
                    graph={presentationData}
                    labelsValue={this.props.totalPresentationViewsShowroom.labels}
                    dataValue={this.props.totalPresentationViewsShowroom.data}
                    dataOptions={dataOptions}
                />
            } else {
                totalPresentationViewsGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalVideoWatchTimeShowroom) {
            totalVideoWatchTimeGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        } else {
            if (this.props.message === "") {
                totalVideoWatchTimeGraph = <GraphComponent
                    graph={videoData}
                    labelsValue={this.props.totalVideoWatchTimeShowroom.labels}
                    dataValue={this.props.totalVideoWatchTimeShowroom.data}
                    dataOptions={dataOptions}
                />
            } else {
                totalVideoWatchTimeGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalTimeSpentShowroom) {
            totalTimeSpentGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        } else {
            if (this.props.message === "") {
                totalTimeSpentGraph = <GraphComponent
                    graph={timeSpentData}
                    labelsValue={this.props.totalUsersTimeSpentShowroom.labels}
                    dataValue={this.props.totalUsersTimeSpentShowroom.data}
                    dataOptions={dataOptions}
                />
            } else {
                totalTimeSpentGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        return (
            <div className={cssClasses.ShowroomAnalyticsMain}>
                {dailyUsersGraph}
                {totalPresentationViewsGraph}
                {totalVideoWatchTimeGraph}
                {totalTimeSpentGraph}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingDailyUserShowroom: state.showroomAnalytics.loadingDailyUserShowroom,
        loadingTotalPresentationViewsShowroom: state.showroomAnalytics.loadingTotalPresentationViewsShowroom,
        loadingTotalVideoWatchTimeShowroom: state.showroomAnalytics.loadingTotalVideoWatchTimeShowroom,
        loadingTotalTimeSpentShowroom: state.showroomAnalytics.loadingTotalTimeSpentShowroom,
        dailyUsersShowroom: state.showroomAnalytics.dailyUsersShowroom,
        totalPresentationViewsShowroom: state.showroomAnalytics.totalPresentationViewsShowroom,
        totalVideoWatchTimeShowroom: state.showroomAnalytics.totalVideoWatchTimeShowroom,
        totalUsersTimeSpentShowroom: state.showroomAnalytics.totalTimeSpentShowroom,
        message: state.showroomAnalytics.message
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserDetails: (body) => {
            dispatch(actions.updateUserDetails(body))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowroomAnalytics));