import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as actions from "./index";

export const userSetUpShowroom = () => {
    return {
        type: actionTypes.SET_UP_SHOWROOM,
        error: false
    };
};

export const userSetUpShowroomSuccess = (message) => {
    return {
        type: actionTypes.SET_UP_SHOWROOM_SUCCESS,
        error: false,
        message: message
    };
};

export const userSetUpShowroomFail = (message) => {
    return {
        type: actionTypes.SET_UP_SHOWROOM_FAIL,
        error: true,
        message: message
    };
};

export const userSetUpShowroomUpdateIndex = (index) => {
    return {
        type: actionTypes.SET_UP_SHOWROOM_UPDATE_INDEX,
        newShowroomIndex: index
    };
};

export const setUpShowroom = (body) => {
    return (dispatch) => {
        dispatch(userSetUpShowroom());

        dispatch(actions.updateUserDetails(body));

        // axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/sendemail', {
        //     subject: body.subject,
        //     date: body.date,
        //     virtualRoom: body.virtualRoom,
        //     hostAccessCode: body.hostAccessCode,
        //     visitorAccessCode: body.visitorAccessCode,
        //     participants: body.participants
        // })
        //     .then(() => {
        //         dispatch(userScheduleMeetingSuccess("Meeting scheduled."));

        //         let meetingBody = {
        //             meetings: body.meetingsArray
        //         }

        //         dispatch(actions.updateUserDetails(meetingBody));
        //     })
        //     .catch((error) => {
        //         if (error.response) {
        //             // console.log("ERR: " + error.response.data.message);
        //             dispatch(userScheduleMeetingFail(error.response.data.message));
        //         }
        //     })
    }
}