import * as actionTypes from "../actionTypes";
import axios from "axios";

export const loadDailyUsers = () => {
    return {
        type: actionTypes.DAILY_USERS_MEETING,
        error: false
    };
};

export const loadDailyUsersSuccess = (dailyUsersMeeting) => {
    return {
        type: actionTypes.DAILY_USERS_MEETING_SUCCESS,
        error: false,
        dailyUsersMeeting: dailyUsersMeeting
    };
};

export const loadDailyUsersFail = (message) => {
    return {
        type: actionTypes.DAILY_USERS_MEETING_FAIL,
        error: true,
        message: message
    };
};

export const getDailyUsersMeeting = (adminUserId, meetingSubject) => {
    return (dispatch) => {
        dispatch(loadDailyUsers());

        let dailyUsers = [];
        let dailyUsersDates = [];
        let dailyUsersActive = [];
        let dailyUsersMeeting = null;

        let data = {
            adminUserId: adminUserId,
            meetingSubject: meetingSubject
        }

        // Daily Users
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/analytics/getdailyusersmeeting',
            data
            , {
                headers: {
                    authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
                }
            })
            .then((res) => {
                for (const dataObj of res.data.dailyUsersAnalyticsData) {
                    dailyUsers.push(dataObj);
                }
                for (const dailyUser of dailyUsers) {
                    dailyUsersDates.push(dailyUser.date);
                    dailyUsersActive.push(dailyUser.users);
                }
                let dailyUsersObject = {
                    labels: dailyUsersDates,
                    data: dailyUsersActive
                }

                dailyUsersMeeting = dailyUsersObject;
                dispatch(loadDailyUsersSuccess(dailyUsersMeeting));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(loadDailyUsersFail(error.response.data.error));
                }
            })
    }
};