import React, { Component } from "react";
import { connect } from "react-redux";
import AuthError from "../../../containers/Auth/AuthError/AuthError";
import AuthSuccess from "../../../containers/Auth/AuthSuccess/AuthSuccess";

import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import cssClasses from "./UpdateShowroom.module.css";

import * as actions from "../../../store/actions/index";

class UpdateShowroom extends Component {

    state = {
        showroomForm: {
            showroomName: {
                valid: true,
                value: "",
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                }
            },
            virtualRoom: "",
            showroomURL: "",
            accessCode: ""
        }
    }

    componentDidMount() {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: {
                ...this.state.showroomForm.showroomName,
                valid: true,
                value: this.props.showroom.showroomName
            },
            virtualRoom: this.props.showroom.virtualRoom,
            showroomURL: this.props.showroom.showroomURL,
            accessCode: this.props.showroom.accessCode,
        }
        this.setState({ showroomForm: updatedShowroomForm });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    isEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = re.test(String(value).toLowerCase());

        return isValid;
    }

    showroomNameChangeHandler = (event) => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: {
                ...this.state.showroomForm.showroomName,
                value: event.target.value
            }
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    showroomNameChangeValidityHandler = () => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: {
                ...this.state.showroomForm.showroomName,
                valid: this.checkValidity(
                    this.state.showroomForm.showroomName.value,
                    this.state.showroomForm.showroomName.validation
                )
            }
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    virtualRoomChangeHandler = (event) => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            virtualRoom: event.target.value
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    submitHandler = () => {
        if (this.state.showroomForm.showroomName.valid) {
            console.log("All are valid here");

            let newShowroomsArray = this.props.showrooms;

            newShowroomsArray[this.props.showroom.showroomIndex] = {
                showroomName: this.state.showroomForm.showroomName.value,
                virtualRoom: this.state.showroomForm.virtualRoom,
                showRoomURL: this.state.showroomForm.showRoomURL,
                accessCode: this.state.showroomForm.accessCode,
            }

            let body = {
                showrooms: newShowroomsArray
            }

            this.props.onUpdateUserDetails(body);
        } else if (!this.state.showroomForm.showroomName.valid) {
            this.props.onAuthError("Showroom name is invalid");
        }
    }

    render() {

        let rooms = null;
        const roomsArray = [];

        for (let key in this.props.rooms) {
            roomsArray.push({
                id: key,
                data: this.props.rooms[key],
            });
        }

        rooms = roomsArray.map((room, index) => (
            <option
                key={room.data.id}
                value={room.data.name}
                defaultValue={room.data.name}
            >{room.data.name}</option>
        ));

        let updateShowroom = <div className={cssClasses.UpdateShowroom}>
            <div className={cssClasses.HeadingText}>
                Modify Showroom
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Showroom Name</p>
                <input className={this.state.showroomForm.showroomName.valid
                    ? cssClasses.InputStyle
                    : cssClasses.Invalid}
                    value={this.state.showroomForm.showroomName.value}
                    onChange={(event) => this.showroomNameChangeHandler(event)}
                    onBlur={this.showroomNameChangeValidityHandler}>
                </input>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Virtual Room</p>
                <select
                    className={cssClasses.InputStyle}
                    value={this.state.showroomForm.virtualRoom}
                    onChange={(event) => this.virtualRoomChangeHandler(event)}>
                    {rooms}
                </select>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Showroom URL</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.showroomForm.showroomURL}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Access Code</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.showroomForm.accessCode}</p>
            </div>
            <button
                className={cssClasses.Button}
                onClick={this.submitHandler}
            >
                <p style={{ height: "57.14%", width: "86.84%", font: "normal normal bold 14px/16px Arial", margin: "3.95% 6.56% 3.95% 6.56%", color: "#FFFFFF", display: "flex", justifyContent: "center", alignItems: "center" }}>Update</p>
            </button>
        </div>;

        if (this.props.loading) {
            updateShowroom = <LoadingSpinner />
        }

        if (this.props.error) {
            updateShowroom = <AuthError authError={this.props.message} />
        }

        if (!this.props.error && this.props.updateShowroom) {
            updateShowroom = <AuthSuccess authSuccess={this.props.message} />
        }
        return (
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {updateShowroom}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.mainApp.error,
        message: state.mainApp.message,
        errorValidation: state.auth.error,
        messageValidation: state.auth.message,
        showroom: state.mainApp.updateShowroomData,
        showrooms: state.mainApp.userDetails.showrooms,
        updateShowroom: state.mainApp.updateShowroom,
        rooms: state.mainApp.userDetails.rooms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserDetails: (body) =>
            dispatch(actions.updateUserDetails(body)),
        onAuthError: (error) =>
            dispatch(actions.authError(error))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateShowroom);