import React, { Component } from "react";
import Layout from "./hoc/Layout/Layout";
import Auth from "./containers/Auth/Auth";
import Login from "./containers/Auth/Login/Login";
import Register from "./containers/Auth/Register/Register";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ChangePassword from "./containers/Auth/ChangePassword/ChangePassword";
import ProfileDashboard from "./containers/Auth/ProfileDashboard/ProfileDashboard";
import AuthAction from "./containers/Auth/AuthAction/AuthAction";
import MainApp from "./containers/MainApp/MainApp";
import Experience from "./containers/Experience/Experience";
import Showroom from "./containers/Showroom/Showroom";
import Meeting from "./containers/Meeting/Meeting";
import ErrorComponent from "./components/ErrorComponent/ErrorComponent";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact path="/" render={() => {
          return <Redirect to="/login" />;
        }} ></Route>
        <Route exact path="/login" component={Login}  ></Route>
        <Route exact path="/register" component={Register}  ></Route>
        <Route exact path="/forgotpassword" component={ForgotPassword}  ></Route>
        <Route exact path="/changepassword" component={ChangePassword}  ></Route>
        <Route exact path="/profile" component={ProfileDashboard}  ></Route>
        <Route exact path="/auth_action" component={AuthAction}  ></Route>
        <Route exact path="/mainapp" component={MainApp}  ></Route>
        <Route exact path="/experience" component={Experience}  ></Route>
        <Route exact path="/meeting" component={Meeting}  ></Route>
        <Route exact path="/showroom" component={Showroom}  ></Route>
        <Route exact path="/error" component={ErrorComponent}  ></Route>
        <Redirect to="/error" />
        .
      </Switch>
    );

    return (
      <Layout>
        <Auth>
          {routes}
        </Auth>
      </Layout>
    );
  }
}

export default withRouter(App);
