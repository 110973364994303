import React, { Component } from "react";
import { connect } from "react-redux";
import AuthError from "../../../containers/Auth/AuthError/AuthError";
import AuthSuccess from "../../../containers/Auth/AuthSuccess/AuthSuccess";

// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import cssClasses from "./SetUpShowroom.module.css";

import * as actions from "../../../store/actions/index";

class SetUpShowroom extends Component {

    state = {
        showroomForm: {
            showroomName: {
                valid: true,
                value: "",
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                }
            },
            virtualRoom: "",
            showroomURL: "",
            accessCode: ""
        }
    }

    componentDidMount() {
        let tempAccessCode = this.generateAccessCode();

        let updatedShowroomForm = {
            ...this.state.showroomForm,
            virtualRoom: this.props.rooms[0].name,
            showroomURL: "https://staging.app.vooth.co/showroom?accessCode=" + tempAccessCode,
            accessCode: tempAccessCode
        }
        this.setState({ showroomForm: updatedShowroomForm });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    isEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = re.test(String(value).toLowerCase());

        return isValid;
    }

    showroomNameChangeHandler = (event) => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: {
                ...this.state.showroomForm.showroomName,
                value: event.target.value
            }
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    showroomNameChangeValidityHandler = () => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            showroomName: {
                ...this.state.showroomForm.showroomName,
                valid: this.checkValidity(
                    this.state.showroomForm.showroomName.value,
                    this.state.showroomForm.showroomName.validation
                )
            }
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    virtualRoomChangeHandler = (event) => {
        let updatedShowroomForm = {
            ...this.state.showroomForm,
            virtualRoom: event.target.value
        }

        this.setState({ showroomForm: updatedShowroomForm });
    }

    generateAccessCode = () => {
        // Declare a string variable 
        // which stores all string
        var string = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let OTP = "";

        // Find the length of string
        var len = string.length;
        for (let i = 0; i < 6; i++) {
            OTP += string[Math.floor(Math.random() * len)];
        }
        return OTP;
    }

    submitHandler = () => {
        if (this.state.showroomForm.showroomName.valid) {
            console.log("All are valid here");

            let newShowroomsArray = [];
            if (this.props.showrooms != null) {
                newShowroomsArray = this.props.showrooms;
            }

            newShowroomsArray.push({
                showroomName: this.state.showroomForm.showroomName.value,
                virtualRoom: this.state.showroomForm.virtualRoom,
                showroomURL: this.state.showroomForm.showroomURL,
                accessCode: this.state.showroomForm.accessCode
            })

            let showroomsBody = {
                showrooms: newShowroomsArray
            }

            this.props.onSetUpShowroom(showroomsBody);
        } else if (!this.state.showroomForm.showroomName.valid) {
            this.props.onAuthError("Showroom name is invalid");
        }
    }

    render() {

        let rooms = null;
        const roomsArray = [];

        for (let key in this.props.rooms) {
            roomsArray.push({
                id: key,
                data: this.props.rooms[key],
            });
        }

        rooms = roomsArray.map((room, index) => (
            <option
                key={room.data.id}
                value={room.data.name}
                defaultValue={room.data.name}
            >{room.data.name}</option>
        ));

        let setUpShowroom = <div className={cssClasses.SetUpShowroom}>
            <div className={cssClasses.HeadingText}>Set up Showroom</div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Showroom Name</p>
                <input
                    className={this.state.showroomForm.showroomName.valid
                        ? cssClasses.InputStyle
                        : cssClasses.Invalid}
                    onChange={(event) => this.showroomNameChangeHandler(event)}
                    onBlur={this.showroomNameChangeValidityHandler}>

                </input>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Virtual Room</p>
                <select
                    className={cssClasses.InputStyle}
                    value={this.state.showroomForm.virtualRoom}
                    onChange={(event) => this.virtualRoomChangeHandler(event)}>
                    {rooms}
                </select>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Showroom URL</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.showroomForm.showroomURL}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Access Code</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.showroomForm.accessCode}</p>
            </div>
            <button
                className={cssClasses.Button}
                onClick={this.submitHandler}
            >
                <p style={{ height: "57.14%", width: "86.84%", font: "normal normal bold 14px/16px Arial", margin: "3.95% 6.56% 3.95% 6.56%", color: "#FFFFFF", display: "flex", justifyContent: "center", alignItems: "center" }}>Save</p>
            </button>
        </div>;

        if (this.props.loading) {
            setUpShowroom = <LoadingSpinner />
        }

        if (this.props.error) {
            setUpShowroom = <AuthError authError={this.props.message} />
        }

        if (!this.props.error && this.props.setUpShowroom) {
            setUpShowroom = <AuthSuccess authSuccess={this.props.message} />
        }

        let errorComponent = null;

        if (this.props.errorValidation) {
            errorComponent = <AuthError authError={this.props.messageValidation} />;
        }

        if (this.state.showroomForm.showroomName.valid) {
            errorComponent = null;
        }

        return (
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {setUpShowroom}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {errorComponent}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.mainApp.loading,
        setUpShowroom: state.mainApp.setUpShowroom,
        error: state.mainApp.error,
        message: state.mainApp.message,
        errorValidation: state.auth.error,
        messageValidation: state.auth.message,
        showrooms: state.mainApp.userDetails.showrooms,
        rooms: state.mainApp.userDetails.rooms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUpShowroom: (body) =>
            dispatch(actions.setUpShowroom(body)),
        onAuthError: (error) =>
            dispatch(actions.authError(error))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetUpShowroom);