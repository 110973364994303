import React, { Component } from "react";
import { connect } from "react-redux";
import ShowroomComponent from "../Showrooms/ShowroomComponent/ShowroomComponent";

import cssClasses from "./Showrooms.module.css";

import * as actions from "../../../store/actions/index";
import { withRouter } from "react-router-dom";

class Showrooms extends Component {

    deleteShowroom = (index) => {
        if (window.confirm('Are you sure you wish to delete this showroom?')) {

            let showroomsArray = [];
            showroomsArray = this.props.showrooms;

            let newShowroomsArray = [];

            showroomsArray.forEach(element => {
                if (element !== showroomsArray[index]) {
                    newShowroomsArray.push(element);
                }
            });

            let body = {
                showrooms: newShowroomsArray
            }
            this.props.onUpdateUserDetails(body);
        }
    }

    render() {

        let showrooms = <h1>No showrooms available yet.</h1>;

        const showroomsArray = [];

        for (let key in this.props.showrooms) {
            showroomsArray.push({
                id: key,
                data: this.props.showrooms[key],
            });
        }

        showrooms = showroomsArray.map((showroom, index) => (
            <ShowroomComponent
                key={showroom.data.id}
                showroomIndex={index}
                showroomName={showroom.data.showroomName}
                virtualRoom={showroom.data.virtualRoom}
                showroomURL={showroom.data.showroomURL}
                accessCode={showroom.data.accessCode}
                delete={() => this.deleteShowroom(index)}
            ></ShowroomComponent>
        ));

        return (
            <div className={cssClasses.Showrooms}>
                {showrooms}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showrooms: state.mainApp.userDetails.showrooms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserDetails: (body) => {
            dispatch(actions.updateUserDetails(body))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Showrooms));