import React, { Component } from "react";
import cssClasses from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";

import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

class NavigationItems extends Component {

  selectBoothCreator = () => {
    if (this.props.boothEditing || this.props.roomEditing) {
      if (window.confirm('Are you sure you wish to leave this page? Unsaved changes will be lost')) {
        this.props.onResetEditing();
        this.props.onBoothCreator();
        this.props.onResetAnalytics();
      }
    } else {
      this.props.onResetEditing();
      this.props.onBoothCreator();
      this.props.onResetAnalytics();
    }
  }

  selectRoomCreator = () => {
    if (this.props.boothEditing || this.props.roomEditing) {
      if (window.confirm('Are you sure you wish to leave this page? Unsaved changes will be lost')) {
        this.props.onResetEditing();
        this.props.onRoomCreator();
        this.props.onResetAnalytics();
      }
    } else {
      this.props.onResetEditing();
      this.props.onRoomCreator();
      this.props.onResetAnalytics();
    }
  }

  selectShowrooms = () => {
    if (this.props.boothEditing || this.props.roomEditing) {
      if (window.confirm('Are you sure you wish to leave this page? Unsaved changes will be lost')) {
        this.props.onResetEditing();
        this.props.onShowrooms();
        this.props.onResetAnalytics();
      }
    } else {
      this.props.onResetEditing();
      this.props.onShowrooms();
      this.props.onResetAnalytics();
    }
  }

  selectMeetings = () => {
    if (this.props.boothEditing || this.props.roomEditing) {
      if (window.confirm('Are you sure you wish to leave this page? Unsaved changes will be lost')) {
        this.props.onResetEditing();
        this.props.onMeetings();
        this.props.onResetAnalytics();
      }
    } else {
      this.props.onResetEditing();
      this.props.onMeetings();
      this.props.onResetAnalytics();
    }
  }

  selectAnalytics = () => {
    if (this.props.boothEditing || this.props.roomEditing) {
      if (window.confirm('Are you sure you wish to leave this page? Unsaved changes will be lost')) {
        this.props.onResetEditing();
        this.props.onAnalytics();
        this.props.onResetAnalytics();
      }
    } else {
      this.props.onResetEditing();
      this.props.onAnalytics();
      this.props.onResetAnalytics();
    }
  }

  render() {
    return (
      <div className={cssClasses.NavigationItems}>
        <div className={cssClasses.NavigationItemsContainer}>
          <NavigationItem name="Booth Creator" clicked={this.selectBoothCreator} />
          <NavigationItem name="Room Creator" clicked={this.selectRoomCreator} />
          <NavigationItem name="Showrooms" clicked={this.selectShowrooms} />
          <NavigationItem name="Meetings" clicked={this.selectMeetings} />
          <NavigationItem name="Analytics" clicked={this.selectAnalytics} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boothEditing: state.mainApp.boothEditing,
    roomEditing: state.mainApp.roomEditing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onBoothCreator: () =>
      dispatch(actions.boothCreator()),
    onRoomCreator: () =>
      dispatch(actions.roomCreator()),
    onShowrooms: () =>
      dispatch(actions.showrooms()),
    onMeetings: () =>
      dispatch(actions.meetings()),
    onAnalytics: () =>
      dispatch(actions.analytics()),
    onResetEditing: () =>
      dispatch(actions.resetEditing()),
      onResetAnalytics: () =>
      dispatch(actions.resetAnalytics())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems);
