import React, { Component } from "react";
import { connect } from "react-redux";
import cssClasses from "./MeetingAnalytics.module.css";

import LoadingSpinner from "../../../UI/LoadingSpinner/LoadingSpinner";

import { withRouter } from "react-router-dom";
import GraphComponent from "../../../UI/GraphComponent/GraphComponent";

const usersData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Active Users (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const videoOutsideMeetingData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total video watch time outside meeting (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const videoInsideMeetingData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total video watch time inside meeting (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const presentationOutsideMeetingData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Presentation views outside meeting (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const presentationInsideMeetingData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Presentation views inside meeting (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const dailyMeetingsData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total number of daily meetings',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const dailyMeetingsDurationData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total duration of daily meetings',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const totalTimeSpentData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: 'Total time spent by user (Daily)',
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const dataOptions = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};

class MeetingAnalytics extends Component {
    render() {

        let dailyUsersGraph = null;
        let totalPresentationViewsOutsideGraph = null;
        let totalPresentationViewsInsideGraph = null;
        let totalVideoWatchTimeOutsideGraph = null;
        let totalVideoWatchTimeInsideGraph = null;
        let dailyMeetingsGraph = null;
        let dailyMeetingsDurationGraph = null;
        let totalTimeSpentGraph = null;

        if (this.props.loadingDailyUserMeeting) {
            dailyUsersGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                dailyUsersGraph = <GraphComponent
                    graph={usersData}
                    labelsValue={this.props.dailyUsersMeeting.labels}
                    dataValue={this.props.dailyUsersMeeting.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                dailyUsersGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalPresentationViewsMeetingOutside) {
            totalPresentationViewsOutsideGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                totalPresentationViewsOutsideGraph = <GraphComponent
                    graph={presentationOutsideMeetingData}
                    labelsValue={this.props.totalPresentationViewsMeetingOutside.labels}
                    dataValue={this.props.totalPresentationViewsMeetingOutside.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                totalPresentationViewsOutsideGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalPresentationViewsMeetingInside) {
            totalPresentationViewsInsideGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                totalPresentationViewsInsideGraph = <GraphComponent
                    graph={presentationInsideMeetingData}
                    labelsValue={this.props.totalPresentationViewsMeetingInside.labels}
                    dataValue={this.props.totalPresentationViewsMeetingInside.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                totalPresentationViewsInsideGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalVideoMeetingOutside) {
            totalVideoWatchTimeOutsideGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                totalVideoWatchTimeOutsideGraph = <GraphComponent
                    graph={videoOutsideMeetingData}
                    labelsValue={this.props.totalVideoMeetingOutside.labels}
                    dataValue={this.props.totalVideoMeetingOutside.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                totalVideoWatchTimeOutsideGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalVideoMeetingInside) {
            totalVideoWatchTimeInsideGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                totalVideoWatchTimeInsideGraph = <GraphComponent
                    graph={videoInsideMeetingData}
                    labelsValue={this.props.totalVideoMeetingInside.labels}
                    dataValue={this.props.totalVideoMeetingInside.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                totalVideoWatchTimeInsideGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingDailyMeetings) {
            dailyMeetingsGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                dailyMeetingsGraph = <GraphComponent
                    graph={dailyMeetingsData}
                    labelsValue={this.props.dailyMeetings.labels}
                    dataValue={this.props.dailyMeetings.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                dailyMeetingsGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingDailyMeetingsDuration) {
            dailyMeetingsDurationGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                dailyMeetingsDurationGraph = <GraphComponent
                    graph={dailyMeetingsDurationData}
                    labelsValue={this.props.dailyMeetingsDuration.labels}
                    dataValue={this.props.dailyMeetingsDuration.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                dailyMeetingsDurationGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        if (this.props.loadingTotalTimeSpentMeeting) {
            totalTimeSpentGraph = <div className={cssClasses.Graph}><LoadingSpinner /> </div>;
        }
        else {
            if (this.props.message === "") {
                totalTimeSpentGraph = <GraphComponent
                    graph={totalTimeSpentData}
                    labelsValue={this.props.totalTimeSpentMeeting.labels}
                    dataValue={this.props.totalTimeSpentMeeting.data}
                    dataOptions={dataOptions}
                />
            }
            else {
                totalTimeSpentGraph = <div className={cssClasses.Graph}>No analytics data found </div>;
            }
        }

        return (
            <div className={cssClasses.MeetingAnalyticsMain}>
                {dailyUsersGraph}
                {totalPresentationViewsOutsideGraph}
                {totalPresentationViewsInsideGraph}
                {totalVideoWatchTimeOutsideGraph}
                {totalVideoWatchTimeInsideGraph}
                {dailyMeetingsGraph}
                {dailyMeetingsDurationGraph}
                {totalTimeSpentGraph}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingDailyUserMeeting: state.meetingAnalytics.loadingDailyUserMeeting,
        loadingTotalPresentationViewsMeetingOutside: state.meetingAnalytics.loadingTotalPresentationViewsMeetingOutside,
        loadingTotalPresentationViewsMeetingInside: state.meetingAnalytics.loadingTotalPresentationViewsMeetingInside,
        loadingTotalVideoMeetingOutside: state.meetingAnalytics.loadingTotalVideoMeetingOutside,
        loadingTotalVideoMeetingInside: state.meetingAnalytics.loadingTotalVideoMeetingInside,
        loadingDailyMeetings: state.meetingAnalytics.loadingDailyMeetings,
        loadingDailyMeetingsDuration: state.meetingAnalytics.loadingDailyMeetingsDuration,
        loadingTotalTimeSpentMeeting: state.meetingAnalytics.loadingTotalTimeSpentMeeting,
        dailyUsersMeeting: state.meetingAnalytics.dailyUsersMeeting,
        totalPresentationViewsMeetingOutside: state.meetingAnalytics.totalPresentationViewsMeetingOutside,
        totalPresentationViewsMeetingInside: state.meetingAnalytics.totalPresentationViewsMeetingInside,
        totalVideoMeetingOutside: state.meetingAnalytics.totalVideoMeetingOutside,
        totalVideoMeetingInside: state.meetingAnalytics.totalVideoMeetingInside,
        dailyMeetings: state.meetingAnalytics.dailyMeetings,
        dailyMeetingsDuration: state.meetingAnalytics.dailyMeetingsDuration,
        totalTimeSpentMeeting: state.meetingAnalytics.totalTimeSpentMeeting,
        message: state.meetingAnalytics.message
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserDetails: (body) => {
            dispatch(actions.updateUserDetails(body))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetingAnalytics));